<template>
  <div>
    <c-search-box @enter="search">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-datepicker
            :range="true"
            defaultStart="-2M"
            defaultEnd="2M"
            type="month"
            label="월(개선 - 요청일 기준 / 즉시조치 - 조치일 기준)"
            name="actionCompleteRequestDates"
            v-model="searchParam.actionCompleteRequestDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 조치부서 -->
          <c-dept 
            type="search" 
            label="조치부서" 
            name="actionDeptCd" 
            v-model="searchParam.actionDeptCd" />
        </div>
      </template>
    </c-search-box>
    <c-tab
      type="tabcard"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :searchParam.sync="searchParam"
          :tabParam.sync="tabParam"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'impr-action-rate',
  data() {
    return {
      searchParam: {
        plantCd: null,
        actionCompleteRequestDates: [],
        actionDeptCd: '',
        actionVendorCd: '',
        actionFlag: 'D',
      },
      tab: 'task',
      tabItems: [
        { name: 'task', icon: 'task_alt', label: '업무별', component: () => import(`${'./imprTaskRate.vue'}`) },
        { name: 'rqstDept', icon: 'campaign', label: '요청대상별', component: () => import(`${'./imprRqstDeptRate.vue'}`) },
      ],
      tabParam: {
        search: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    search() {

    },
  }
};
</script>
